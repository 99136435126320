import { api } from "roboflex-thalamus-request-handler";

function gravarStatus(payload){
    return new Promise((resolve, reject) => {
        return api.post('dre/status-edicao/gravar',payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
}

function getUltimoStatus(payload){
    return new Promise ((resolve, reject) => {
        return api.get(`dre/status-edicao/listar`, {params: payload})
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
}
 function getTodosStatus(){
    return new Promise ((resolve, reject) => {
        return api.get(`dre/status-edicao/listar`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })

 }




export default {
    gravarStatus,
    getTodosStatus,
    getUltimoStatus
   
}