import { api } from "roboflex-thalamus-request-handler";

function obterTodos(){
    return new Promise((resolve, reject) => {
        return api.get('pessoa')
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
}

function associarResponsavel(payload){
    return new Promise ((resolve, reject) => {
        return api.post(`dre/categoria/associar/responsavel`,payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
}
 function excluirResponsavel(payload){
    return new Promise ((resolve, reject) => {
        return api.post(`dre/categoria/excluir/responsavel`,payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })

 }




export default {
    obterTodos,
    associarResponsavel,
    excluirResponsavel
   
}