import { createRouter, createWebHashHistory } from 'vue-router'
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "../services/permissao-service";

//Projeto
import Associar from '../views/Orçamentos/AssociarView.vue'

//Orçamento
import RelatorioOrcamento from '../views/Orçamentos/RelatorioOrcamento'
import OrcamentoPorProjeto from '../views/Orçamentos/OrçamentoPorProjeto.vue'
import CadastroOrcamentoPlanilha from '@/views/Orçamentos/CadastroOrcamentoPlanilha.vue'
import CadastroOrcamentoManual from '@/views/Orçamentos/CadastroOrcamentoManual.vue'
import OrcamentosView from '@/views/Orçamentos/OrcamentosView.vue'

//Receitas
import CadastroReceitas from '@/views/Receitas/CadastroReceitas.vue';
import EstruturaReceitas from '@/views/Receitas/EstruturaReceitas.vue';
import RelatorioDashboard from '@/views/Receitas/ReceitasView.vue'

//Estrutura
import EstruturaPlano from '@/views/Orçamentos/EstruturaPlano.vue'



// redireciona usuario para LOGIN baseado no env
function enviarUsuarioLogin() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/login";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/login";
  }
}

// redireciona usuario para PORTAL baseado no env
function enviarUsuarioPortal() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/";
  }
}

// apenas valida se esta logado
function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    enviarUsuarioLogin();
  }
}

// apenas valida se tem permissao
async function guardPermissao(to, from, next) {
  const permissoes = await getPermissao();
  const funcionalidadeNecessaria = to.meta.funcionalidadeId;
  if (funcionalidadeNecessaria && !permissoes.includes(funcionalidadeNecessaria)) {
    enviarUsuarioPortal();
  } else {
    next();
  }
}

// combinacao permissao + verificacao logado
function guardPermissaoRoute(to, from, next) {
  guardMyroute(to, from, (guardResultado) => {
    if (guardResultado !== undefined) {
      next(guardResultado);
    } else {
      guardPermissao(to, from, next);
    }
  });
}

const routes = [
  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },

  //Receitas
  {
    path: '/receitas',
    name: 'EstruturaReceitas',
    component: EstruturaReceitas,
    beforeEnter: guardPermissaoRoute,
  },


  {
    path: '/cadastrar/receita',
    name: 'CadastroReceita',
    component: CadastroReceitas,
    beforeEnter: guardPermissaoRoute,
  },

  //Estrutura
  {
    path: '/',
    name: 'EstruturaPlano',
    component: EstruturaPlano,
    beforeEnter: guardPermissaoRoute,
  },

  //Projeto
  {
    path: '/associar',
    name: 'Associar',
    component: Associar,
    beforeEnter: guardPermissaoRoute,

  },

  //Orçamento
  {
    path: '/relatorio/orcamento',
    name: 'RelatorioOrcamento',
    component: RelatorioOrcamento,
    beforeEnter: guardPermissaoRoute,

  
  },
  {
    path: '/relatorio/dashboard',
    name: 'RelatorioDashboard',
    component: RelatorioDashboard,
    beforeEnter: guardPermissaoRoute,

  },

  {
    path: '/cadastrar/orcamento/planilha',
    name: 'OrcamentoCadastroPlanilha',
    component: CadastroOrcamentoPlanilha,
    beforeEnter: guardPermissaoRoute,

  },

  {
    path: '/cadastrar/orcamento/manual',
    name: 'OrcamentoCadastroManual',
    component: CadastroOrcamentoManual,
    beforeEnter: guardPermissaoRoute,

  },

  {
    path: '/orcamento/projeto',
    name: 'OrcamentoProjeto',
    component: OrcamentoPorProjeto,
    beforeEnter: guardPermissaoRoute,

  },

  {
    path: '/relatorio/geral',
    name: 'OrcamentosView',
    component: OrcamentosView,
    beforeEnter: guardPermissaoRoute,

  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
