import { api } from "roboflex-thalamus-request-handler";

function obterEstrutura(payload) {
    return new Promise((resolve, reject) => {
        return api.post(`dre/estrutura/listar`, payload)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}
function obterEstruturaSemAno() {
    return new Promise((resolve, reject) => {
        return api.post(`dre/estrutura/listar`)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}
function excluirGrupoEstrutura(id) {
    return new Promise((resolve, reject) => {
        return api.delete(`dre/estrutura/excluir/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}

function criarGrupoEstrutura(payload) {
    return new Promise((resolve, reject) => {
        return api.post(`dre/estrutura/gravar`, payload)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}

function associarCategoriaGrupo(payload) {
    return new Promise((resolve, reject) => {
        return api.post(`dre/categoria/gravar`, payload)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}

function excluirCategoriaGrupo(id) {
    return new Promise((resolve, reject) => {
        return api.delete(`dre/categoria/excluir/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}

function listarCategorias() {
    return new Promise((resolve, reject) => {
        return api.get(`dre/categoria/listar`)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}

export default {
    obterEstrutura,
    excluirGrupoEstrutura,
    criarGrupoEstrutura,
    associarCategoriaGrupo,
    excluirCategoriaGrupo,
    listarCategorias,
    obterEstruturaSemAno
};
