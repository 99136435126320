import { api } from "roboflex-thalamus-request-handler";

function cadastroPlanilhaManual(payload){
    return new Promise((resolve, reject) => {
        return api.post(`dre/categoria/orcamento/gravar-atualizar`,payload)
        .then(response => resolve(response))
        .catch(error => reject(error));

    });
}

export default {
    cadastroPlanilhaManual
}